import React, { JSX } from "react";
import { useRouteLoaderData } from "react-router";
import { FieldsAndResults } from "../../../../../../API/fields";
import { CrumbProps } from "../../../../../../Component/Breadcrumbs/Breadcrumb";
import DisplayDate from "../../../../../../Component/DisplayDate/DisplayDate";
import { ProductName } from "../../../../../../Component/Product/ProductName";

export default function ResultSetCrumb({
  match,
}: CrumbProps<"resultSetId">): JSX.Element | undefined {
  const { resultSets } = useRouteLoaderData("customer") as FieldsAndResults;

  const resultSet = resultSets.find(
    (resultSet) => resultSet.id === match.params.resultSetId,
  );

  return (
    resultSet && (
      <>
        <ProductName sku={resultSet.product_sku} />{" "}
        <DisplayDate date={resultSet.detection_dates[0]} />
      </>
    )
  );
}
