import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { DateTime, DateTimeFormatOptions } from "luxon";

type DisplayDateProps = {
  date: string | null | DateTime;
  format?: DateTimeFormatOptions;
};

export default function DisplayDate({
  date,
  format = DateTime.DATE_FULL,
}: DisplayDateProps): JSX.Element {
  const { t, i18n } = useTranslation();
  if (!date) {
    return <i>{t("Unknown")}</i>;
  }
  const asLuxon: DateTime =
    date instanceof DateTime ? date : DateTime.fromISO(date, {});

  return (
    <time dateTime={asLuxon.toISODate() as string}>
      {asLuxon.setLocale(i18n.language).toLocaleString(format)}
    </time>
  );
}
