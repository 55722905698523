import { TextField } from "@mui/material";
import React, { JSX, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../Component/Button/Button";
import MessageFlash, {
  MessageLevel,
} from "../../../Component/message-flash/MessageFlash";

export type onSubmitVerifyEmail = (params: { email: string }) => Promise<void>;

export type submitVerifyEmailResult = null | "success" | "error" | "invalid";

type VerifyEmailFormProps = {
  onSubmitVerifyEmail: onSubmitVerifyEmail;
  message: submitVerifyEmailResult;
};

export default function VerifyEmailForm({
  onSubmitVerifyEmail,
  message = null,
}: VerifyEmailFormProps): JSX.Element {
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("title.VerifyEmail")}</h1>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmitVerifyEmail({ email });
        }}
      >
        <fieldset className="boxed">
          <div className="vspace">
            {message === "success" && (
              <MessageFlash
                message={t("message.verify email sent")}
                level={MessageLevel.Success}
              />
            )}
            {message === "error" && (
              <MessageFlash
                message={t("message.something went wrong")}
                level={MessageLevel.Error}
              />
            )}
            {message === "invalid" && (
              <MessageFlash
                message={t("message.Invalid email address provided")}
                level={MessageLevel.Warning}
              />
            )}
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Email")}
              required={true}
              variant="outlined"
              fullWidth={true}
              value={email}
              type="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
          </div>
          <div className="vspace">
            <Button
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.Send verification email")}
            </Button>
          </div>
          <div className="vspace">
            <Link to={"/login"}>{t("link.login")}</Link>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
