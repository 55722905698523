import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export type ProductNameProps = {
  sku: string;
};

const SKU_TRANSLATIONS: Record<string, string> = {
  OR2: "product.Blossom map",
  OR1: "product.Vigour map",
  "TS-BLOSSOM": "product.Blossom map",
  "TS-VIGOUR": "product.Vigour map",
};

export function ProductName({ sku }: ProductNameProps): JSX.Element {
  const { t } = useTranslation();

  return <>{translateProductName(t, sku)}</>;
}

export function translateProductName(t: TFunction, sku: string): string {
  if (SKU_TRANSLATIONS[sku]) {
    return t(SKU_TRANSLATIONS[sku]) as string;
  }
  return t("product.unknownProduct") as string;
}
