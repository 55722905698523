import { getUrl } from "../AureaApi";

export class ResetPasswordError extends Error {
  constructor(
    public response: Response,
    options: ErrorOptions = {},
  ) {
    super("An unexpected error occurred during password reset.", options);
  }
}

export class NoTokenError extends Error {
  constructor(options: ErrorOptions = {}) {
    super("No token has been provided", options);
  }
}

export class InvalidTokenError extends Error {
  constructor(options: ErrorOptions = {}) {
    super("Invalid token has been provided", options);
  }
}
export async function extractToken({
  request,
}: {
  request: Request;
}): Promise<string> {
  const token = new URL(request.url).searchParams.get("token");
  if (!token) {
    throw new NoTokenError();
  }
  if (token.split(".").length !== 3) {
    throw new InvalidTokenError();
  }
  return token;
}

export async function resetPassword(
  password: string,
  token: string,
): Promise<void> {
  const response = await fetch(getUrl(`/password_reset`), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ password }),
  });

  if (response.status >= 400) {
    throw new ResetPasswordError(response);
  }
}
