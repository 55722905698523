import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Locize from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const supportedLngs = ["en", "nl"];

export const translationsLoaded = i18n
  .use(Locize)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: import.meta.env.DEV,
    fallbackLng: "en",
    defaultNS: "frontend",
    ns: "frontend",
    interpolation: {
      escapeValue: false,
    },
    supportedLngs,
    saveMissing: import.meta.env.DEV,
    backend: {
      projectId: "d22816ae-583e-4321-8a91-e2f544f6a939",
      referenceLng: "en",
      apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
      allowedAddOrUpdateHosts: ["localhost"],
      version: "latest",
    },
  });

export default i18n;
