import React, { JSX } from "react";
import RegisterAccountForm from "./RegisterAccountForm";
import { Splash } from "../../../Layout";

export default function RegisterAccount(): JSX.Element | null {
  return (
    <Splash>
      <RegisterAccountForm />
    </Splash>
  );
}
