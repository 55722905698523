import { useAuth } from "./auth";
import { LoaderFunctionArgs, Params } from "@remix-run/router/utils";
import { get, getUrl } from "./AureaApi";
import {
  FeatureCollection,
  Geometry,
  MultiPolygon as GeoJsonMultiPolygon,
  Polygon as GeoJsonPolygon,
} from "geojson";
import { ActionFunctionArgs } from "react-router";
import { MessageLevel } from "../Component/message-flash/MessageFlash";

type GetCustomerFieldParams = LoaderFunctionArgs & {
  params: Params<"customerId" | "fieldId">;
};

export type FieldProperties = {
  name: string;
  remark: string | null;
  location_link: string | null;
  plant_date: `${number}-${number}-${number}` | null;
  crop: string | null;
  variety: string | null;
  planting_distance_meters: number | null;
  location_id: string | null;
  id: number;
  customer_id: number;
  area_ha: number;
};

export type FieldsGeoJSON = FeatureCollection<
  GeoJsonMultiPolygon | GeoJsonPolygon,
  FieldProperties
>;

export type FieldsAndResults = {
  fields: FieldsGeoJSON;
  resultSets: [
    {
      id: string;
      analysis_id: string;
      product_sku: string;
      field_id: number;
      detection_dates: [`${number}-${number}-${number}`];
      external_visibility: Array<string>;
      created_at: string;
      updated_at: string;
    },
  ];
  tilings: Tiling[];
  customer: {
    id: number;
  };
};

export type Tiling = {
  status: string;
  id: number;
  customer_id: number;
  extent_polygon?: GeoJsonPolygon | null;
  flight_date: `${number}-${number}-${number}` | null;
  created_at: `${number}-${number}-${number}`;
  label: string;
  max_zoom_level?: number;
};

export async function getCustomerFieldsAndResultSets({
  params: { customerId },
}: GetCustomerFieldParams): Promise<null | FieldsAndResults> {
  const [fields, resultSets, tilings] = await Promise.all([
    get<FieldsGeoJSON>(`/v1/customer/${customerId}/field/boundary/geojson`),
    get<FieldsAndResults["resultSets"]>(
      `/v1/customer/${customerId}/result_set?limit=1000`,
    ),
    get<Tiling[]>(`/v1/customer/${customerId}/tiling`),
  ]);

  return {
    fields,
    resultSets,
    tilings,
    customer: {
      id: Number(customerId),
    },
  };
}

export async function updateFieldProps({
  request,
  params,
}: ActionFunctionArgs): Promise<MessageLevel> {
  const token = await useAuth.getState().getToken();
  const formData = await request.formData();
  const plantDate = formData.get("plantingDate") as string;

  const fieldData = {
    name: formData.get("name") as string,
    crop: formData.get("crop") as string,
    variety: formData.get("variety") as string,
    planting_distance_meters: Number(formData.get("plantDistance") || 0) * 0.01,
    plant_date: plantDate ? new Date(plantDate).toISOString() : undefined,
    remark: formData.get("remarks") as string,
  };

  const response = await fetch(getUrl(`/v1/field/${params.fieldId}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(fieldData),
  });

  return (
    {
      200: MessageLevel.Success,
      422: MessageLevel.Error,
    }[response.status] || MessageLevel.Error
  );
}

export async function actionUpdateFieldExtent({
  request,
  params,
}: ActionFunctionArgs): Promise<MessageLevel> {
  const result = await updateFieldExtent(
    Number(params.fieldId),
    await request.text(),
  );
  return result.status < 300 ? MessageLevel.Success : MessageLevel.Error;
}

async function updateFieldExtent(
  fieldId: number,
  body: string,
): Promise<Response> {
  const token = await useAuth.getState().getToken();
  return fetch(getUrl(`/v1/field/${fieldId}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });
}

export async function saveNewField(
  customerId: number,
  fieldData: {
    name: string;
    crop: string;
    variety: string;
    planting_distance_meters: number;
    plant_date?: string | undefined;
    remark: string;
    boundary: Geometry;
  },
): Promise<Response> {
  const token = await useAuth.getState().getToken();
  return await fetch(getUrl(`/v1/customer/${customerId}/field`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(fieldData),
  });
}
