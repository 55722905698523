import { createTheme, ThemeProvider } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import React, { JSX } from "react";
import NavBar from "./Component/NavBar/NavBar";
import "./css/Colors.css";
import "./css/Layout.css";

type LayoutProps = {
  children: React.ReactNode;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#50BE64",
      contrastText: "white",
    },
    secondary: {
      main: "#618c85",
      dark: "#0f5046",
      light: "#b0c5c2",
    },
    text: {
      primary: "#00323c",
    },
    warning: {
      main: "#fa9600",
    },
    error: {
      main: "#E82417",
    },
    info: {
      main: "#0672D5",
    },
  },
  typography: {
    fontFamily: "exo2, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#FFF",
          textTransform: "none",
          "&:hover": {
            color: "#FFF",
          },
        },
        text: {
          color: "#618C85",
          backgroundColor: "transparent",
          "&:hover": {
            color: "#0F5046",
            backgroundColor: "transparent",
          },
        },
        outlined: {
          color: "#618C85",
          backgroundColor: "#F0FFF5",
          border: "solid 1px #C3DCD2",
          "&:hover": {
            color: "#0F5046",
            borderColor: "#C3DCD2",
            backgroundColor: "#C3DCD2",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#FFF",
            backgroundColor: "primary.main",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(97,140,133,0.2)",
          },
        },
      },
    },
  },
});

export default function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-root">{children}</div>
    </ThemeProvider>
  );
}

export function Splash({ children }: LayoutProps): JSX.Element {
  return (
    <>
      <NavBar hasDrawer={false} drawerWidth={null} />
      <div className="splash">
        <div className="splash-inner">{children}</div>
      </div>
    </>
  );
}
