import { RouteObject } from "react-router";
import { getAllCustomers } from "../../../API/customer";
import CustomerSelect from "./CustomerSelect";
import CustomerSelectCrumb from "./CustomerSelectCrumb";

const routes: RouteObject = {
  id: "customer-select",
  index: true,
  Component: CustomerSelect,
  loader: getAllCustomers,
  handle: {
    Crumb: CustomerSelectCrumb,
  },
};

export default routes;
