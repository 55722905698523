import React, { JSX, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type RequiredSelectProps = {
  defaultValue: string;
};

export default function CropSelect({
  defaultValue,
}: RequiredSelectProps): JSX.Element {
  const { t } = useTranslation();
  const [[show, value], setValue] = useState<[boolean, string]>([
    false,
    defaultValue,
  ]);
  const ref = useRef<{ node: HTMLInputElement }>();

  const error: string = !value
    ? (t("error.Please select a crop") as string)
    : "";

  useEffect(() => {
    if (ref.current) {
      ref.current.node.setCustomValidity(error || "");
    }
  }, [error]);

  return (
    <FormControl
      fullWidth
      error={!!error && show}
      aria-invalid={!!error && show}
      aria-errormessage={String(show) && error}
    >
      <InputLabel
        id="field-property-crop-label"
        htmlFor="field-property-crop"
        required={true}
      >
        {t("label.Crop")}
      </InputLabel>
      <Select
        labelId="field-property-crop-label"
        id="field-property-crop"
        name="crop"
        defaultValue={defaultValue}
        label={t("label.Crop")}
        onChange={(ev) => setValue([true, ev.target.value])}
        inputRef={ref}
        required
      >
        <MenuItem value="apple">{t("crop.apple.title")}</MenuItem>
        <MenuItem value="pear">{t("crop.pear.title")}</MenuItem>
      </Select>
    </FormControl>
  );
}
