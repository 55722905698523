import React, { JSX } from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth } from "../../API/auth";

export default function RequireLogin(): JSX.Element {
  const auth = useAuth();
  if (!auth.accessToken) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
