import React, { createContext, useState, useContext } from "react";

const LoadingContext = createContext({
  loading: false,
  setLoading: (loading: boolean) => {}, // eslint-disable-line
});

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
