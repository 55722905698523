import { RouteObject } from "react-router";
import Home from "./Home";

const routes: RouteObject = {
  id: "home",
  path: "/",
  Component: Home,
};

export default routes;
