import React from "react";
import styles from "./LoadingSpinner.module.css";
import { useTranslation } from "react-i18next";
import { Backdrop, CircularProgress } from "@mui/material";

export default function LoadingSpinner() {
  const { t } = useTranslation();

  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: 1000,
        width: "100%",
        height: "100%",
        left: "unset",
        right: "unset",
        top: "unset",
        bottom: "unset",
      }}
    >
      <div className={styles.background}>
        <CircularProgress sx={{ color: "var(--color-neutral-0)" }} />
        <p className={styles.title}>{t("message.Loading")}</p>
      </div>
    </Backdrop>
  );
}
