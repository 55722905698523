import React, { JSX } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { CustomerList } from "../../../API/customer";
import { Splash } from "../../../Layout";
import CustomerSelectForm from "./CustomerSelectForm";

export default function CustomerSelect(): JSX.Element {
  const customers = useLoaderData() as CustomerList;
  const navigate = useNavigate();

  return (
    <Splash>
      <CustomerSelectForm
        customers={customers}
        onCustomerSelect={async (customerId) =>
          navigate(`/customer/${customerId}`)
        }
      />
    </Splash>
  );
}
