import React, { JSX } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./I18next";

export type I18nextProviderProps = {
  children: JSX.Element;
};

export default function I18Provider({
  children,
}: I18nextProviderProps): JSX.Element {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
