import React from "react";
import { useRouteLoaderData } from "react-router";
import { FieldsAndResults } from "../../../../../API/fields";
import { CrumbProps } from "../../../../../Component/Breadcrumbs/Breadcrumb";

export default function FieldCrumb({
  match,
}: CrumbProps<"fieldId">): React.ReactNode {
  const customer = useRouteLoaderData("customer") as FieldsAndResults;

  if (!match.params.fieldId) {
    return null;
  }

  const feature = customer.fields.features.find(
    (feature) => feature.properties.id === Number(match.params.fieldId),
  );
  return feature && feature.properties.name;
}
