import React, { useState } from "react";
import styles from "../Field/FieldMap.module.css";
import useLayerEvent from "./useLayerEvent";

export type PopupProps<T> = {
  layer: string;
  component: (props: { properties: T }) => React.ReactNode;
};

export default function Popup<T>({ layer, component }: PopupProps<T>) {
  const [popup, setPopup] = useState<null | {
    x: number;
    y: number;
    properties: T;
  }>(null);

  useLayerEvent(
    "mousemove",
    layer,
    /* istanbul ignore next */ (event) => {
      if (event.features?.length) {
        setPopup({
          x: event.point.x,
          y: event.point.y + 10,
          properties: event.features[0].properties as unknown as T,
        });
      } else {
        setPopup(null);
      }
    },
  );

  useLayerEvent(
    "mouseleave",
    layer,
    /* istanbul ignore next */ () => {
      setPopup(null);
    },
  );

  return (
    popup && (
      <div
        className={styles.tooltip}
        style={{
          top: popup.y,
          left: popup.x,
        }}
      >
        {React.createElement(component, { properties: popup.properties })}
      </div>
    )
  );
}
