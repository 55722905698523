import { RouteObject } from "react-router";
import RequireLogin from "./RequireLogin";
import select from "./select";
import customer from "./_customer_id";

const routes: RouteObject = {
  Component: RequireLogin,
  path: "customer",
  children: [select, customer],
};

export default routes;
