import { useSearchParams } from "react-router-dom";

export default function useShowTrees(): [boolean, (enabled: boolean) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const showTrees = searchParams.get("showTrees") === "true";
  const setShowTrees = (enabled: boolean): void => {
    searchParams.set("showTrees", enabled ? "true" : "false");
    setSearchParams(searchParams);
  };
  return [showTrees, setShowTrees];
}
