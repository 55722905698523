import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./BlossomLegend.module.css";

export type BlossomLegendProps = {
  maxDensity: number;
  minDensity: number;
};

export default function BlossomLegend({
  maxDensity,
  minDensity,
}: BlossomLegendProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <aside className={`${styles.blossomLegend} boxed`}>
      <h1 className={styles.heading}>{t("heading.Blossom area")}</h1>

      <Box className={styles.gradientBox}></Box>

      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <p> {minDensity}</p>
        </Grid>
        <Grid item container xs={6} alignItems="flex-end" direction="column">
          <Grid item>
            <p>{maxDensity}</p>
          </Grid>
        </Grid>
      </Grid>
    </aside>
  );
}
