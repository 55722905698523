import { FeatureCollection, Point } from "geojson";
import { get } from "./AureaApi";
import { LoaderFunctionArgs, Params } from "@remix-run/router/utils";

export type GetDrivingDayByFieldParams = LoaderFunctionArgs & {
  params: Params<"fieldId">;
};

export type TreePhotoPositions = FeatureCollection<
  Point,
  { image_id: string; yaw: number; heading: number }
>;

export type DrivingDay = {
  id: string;
  customer_id: number;
  field_id: number;
  tms_app_id: string;
  drive_ids: string[];
  day_start_time: string;
  day_end_time: string;
  created_at: string;
  updated_at: string;
};

export type TreeScoutData = {
  drivingDays: DrivingDay[];
};

export async function getDrivingDayByFieldId({
  params: { fieldId },
}: GetDrivingDayByFieldParams): Promise<DrivingDay[]> {
  return get(`/v1/field/${fieldId}/driving_day`);
}

export async function getPhotosByDrivingDay(
  drivingDayId: string,
): Promise<TreePhotoPositions> {
  return get<TreePhotoPositions>(
    `/v1/driving_day/${drivingDayId}/sensor_image/geojson`,
  );
}
