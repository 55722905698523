import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import styles from "./VigourLegend.module.css";

const rootStyle = getComputedStyle(document.documentElement);
const ColorDefinitionMap = new Map<number, string>([
  [1, rootStyle.getPropertyValue("--color-vigour-map-1")], // dark orange
  [2, rootStyle.getPropertyValue("--color-vigour-map-2")], // orange
  [3, rootStyle.getPropertyValue("--color-vigour-map-3")], // yellow
  [4, rootStyle.getPropertyValue("--color-vigour-map-4")], // lime green
  [5, rootStyle.getPropertyValue("--color-vigour-map-5")], // light green
  [6, rootStyle.getPropertyValue("--color-vigour-map-6")], // grass green
  [7, rootStyle.getPropertyValue("--color-vigour-map-7")], // dark green
]);

const colors = [...ColorDefinitionMap.entries()];
const mappedColors = colors.map(([key, value]) => {
  return (
    <div key={key} className={styles.vigourLegendItem}>
      <div
        className={styles.vigourLegendItemBox}
        style={{ backgroundColor: value }}
      ></div>
      <div className={styles.vigourLegendItemText}>{key}</div>
    </div>
  );
});

export default function VigourLegend(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={`${styles.vigourLegend} boxed`}>
      <p>
        <strong>{t("heading.Vigour Class")}</strong>
      </p>
      <div className={styles.vigourLegendItems}>{mappedColors}</div>
    </div>
  );
}
