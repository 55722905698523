import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../API/auth";
import { AppBar, Box, Button, IconButton, Menu } from "@mui/material";
import { KeyboardArrowDown, Language, Person } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./NavBar.module.css";
import Logo from "../Logo/Logo";
import Breadcrumbs from "../Breadcrumbs/Breadcrumb";
import { supportedLngs } from "../../i18n/I18next";
import { Link } from "react-router-dom";

function displayLanguage(language: string): string {
  const display = new Intl.DisplayNames(language, {
    type: "language",
  });
  const [initial, ...rest] = [...(display.of(language) as string)];
  return [initial.toLocaleUpperCase(language), ...rest].join("");
}

interface NavBarProps {
  drawerWidth: number | null;
  handleDrawerToggle?: () => void;
  hasDrawer: boolean | null;
}

export default function NavBar({
  handleDrawerToggle,
  drawerWidth = 0,
  hasDrawer = true,
}: NavBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();
  const auth = useAuth();

  const handleMenuLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      className={styles.appBar}
      sx={{
        width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <div className={styles.breadcrumbs}>
        {hasDrawer && (
          <>
            <IconButton
              className={styles.toggleButton}
              sx={{
                display: { xs: "flex", sm: "none" },
              }}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Breadcrumbs mobileView={false} />
          </>
        )}

        <Box
          className={styles.logoContainer}
          sx={{
            display: {
              xs: "flex",
              sm: hasDrawer ? "none" : "flex",
            },
          }}
        >
          <Link to="">
            <Logo
              width={90}
              height={38}
              iconColor="var(--color-green-light)"
              textColor="var(--color-green-dark)"
            />
          </Link>
        </Box>
      </div>
      <div className={styles.toolBar}>
        <Button
          onClick={handleMenuLang}
          className={styles.navItem}
          color="inherit"
        >
          <div className={styles.navItemIcon}>
            <Language />
          </div>
          <div className={styles.navItemWrapper}>
            <div className={styles.navItemText}>
              {i18n.language.toLocaleUpperCase(i18n.language)}
            </div>
            <KeyboardArrowDown />
          </div>
        </Button>
        <Menu
          id="menu-language"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 48,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseLanguage}
        >
          {supportedLngs.map((language) => {
            return (
              <MenuItem
                key={language}
                onClick={() => i18n.changeLanguage(language)}
              >
                {displayLanguage(language)}
              </MenuItem>
            );
          })}
        </Menu>
        <div className={styles.navSpacer}>
          {auth.username ? <LoggedInMenu /> : <LoggedOutMenu />}
        </div>
      </div>
    </AppBar>
  );
}

function LoggedOutMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLogin = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        className={styles.navItem}
        aria-label="account of logged out user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <div className={styles.navItemIcon}>
          <Person />
        </div>
        <div className={styles.navItemWrapper}>
          <div className={styles.navItemText}>{t("Menu.login")}</div>
          <KeyboardArrowDown />
        </div>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 48,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseLogin}
      >
        <MenuItem>{t("Menu.Create account")}</MenuItem>
      </Menu>
    </>
  );
}

function LoggedInMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const auth = useAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLogin = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={styles.navItem}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <div className={styles.navItemIcon}>
          <Person />
        </div>
        <div className={styles.navItemWrapper}>
          <div className={styles.navItemText}>{auth.username}</div>
          <KeyboardArrowDown />
        </div>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 48,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseLogin}
      >
        <MenuItem onClick={auth.logout}>{t("menu.Logout")}</MenuItem>
      </Menu>
    </>
  );
}
