import { RouteObject } from "react-router";
import Support from "./Support";
import SupportCrumb from "./SupportCrumb";

const routes: RouteObject = {
  Component: Support,
  path: "support",
  handle: {
    Crumb: SupportCrumb,
  },
};

export default routes;
