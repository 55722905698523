import React from "react";
import { Grid, List, ListItem } from "@mui/material/";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";

import styles from "./Support.module.css";

type LinkTranslation = Array<{
  url: string;
  title: string;
}>;

export default function Support(): JSX.Element {
  const { i18n, t } = useTranslation();
  const supportLinks = i18n.t("support.links", {
    returnObjects: true,
  }) as LinkTranslation;

  const safeSupportLinks = Array.isArray(supportLinks) ? supportLinks : [];

  return (
    <div className={styles.supportPage}>
      <SupportHeader />
      <div className={styles.content}>
        <div className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h2>{t("heading.Useful links")}</h2>
              <List component="div">
                {safeSupportLinks.map((link, idx) => (
                  <ListItem
                    key={idx}
                    sx={{
                      margin: 0,
                      padding: "4px 0",
                    }}
                    component="a"
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                    href={link.url}
                  >
                    {link.title}
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h2>{t("heading.Get support")}</h2>
              <SupportBlock />
            </Grid>
          </Grid>
        </div>
      </div>
      <SupportBanner />
    </div>
  );
}

function SupportHeader(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.supportHeader}>
      <div className={styles.container}>
        <h1>{t("heading.Help and support")}</h1>
      </div>
    </div>
  );
}

function SupportBlock(): JSX.Element {
  const { t } = useTranslation();
  return (
    <a
      href="https://aureaimaging.atlassian.net/servicedesk/customer"
      target="_blank"
      rel="noreferrer"
      className={styles.supportBlock}
    >
      <div className={styles.supportBlockIcon}>
        <SupportAgentIcon sx={{ fontSize: 53 }} />
      </div>
      <div>
        <h3>{t("heading.Customer service")}</h3>
        <p className={styles.supportBlockText}>
          {t("text.Create or view a support ticket")}
        </p>
      </div>
    </a>
  );
}

function SupportBanner(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.banner}>
      <div className={styles.container}>
        <h2>{t("heading.Need help")}</h2>
        <h3 className={styles.bannerText}>{t("text.Contact you agent")}</h3>
      </div>
    </div>
  );
}
