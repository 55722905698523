import { useSearchParams } from "react-router-dom";

export function usePhotosDrivingDay(): [string, (day: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const photosDrivingDay = searchParams.get("photosDrivingDay") || "";
  const setPhotosDrivingDay = (day: string): void => {
    searchParams.set("photosDrivingDay", day);
    setSearchParams(searchParams);
  };
  return [photosDrivingDay, setPhotosDrivingDay];
}
