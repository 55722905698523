import React, { JSX } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import styles from "./dashboard.module.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";

import NavBar from "../NavBar/NavBar";
import { NavLink, Outlet, useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumb";
import classNames from "classnames";

const drawerWidth = 240;

type MenuLinkProps = {
  to: string;
  navigate: (to: string) => void;
  children: React.ReactNode;
};

function MenuLink({ to, navigate, children }: MenuLinkProps): JSX.Element {
  return (
    <li className={styles.dashboardListItem}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames({
            [styles.dashboardListItemAnchor]: true,
            [styles.active]: isActive,
          })
        }
        onClick={() => navigate(to)}
      >
        {children}
      </NavLink>
    </li>
  );
}

interface ResponsiveDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export function ResponsiveDrawer({
  mobileOpen,
  handleDrawerToggle,
}: ResponsiveDrawerProps) {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeDrawerAndNavigate = (path: string) => {
    handleDrawerToggle();
    navigate(path);
  };

  const drawer = (
    <div>
      {customerId ? <ShowLogo /> : ""}
      <ul className={styles.dashboardList}>
        <MenuLink
          to={`/customer/${customerId}/field`}
          navigate={closeDrawerAndNavigate}
        >
          {t("menu.fields")}
        </MenuLink>
        <MenuLink
          to={`/customer/${customerId}/order`}
          navigate={closeDrawerAndNavigate}
        >
          {t("menu.orders")}
        </MenuLink>
        <MenuLink
          to={`/customer/${customerId}/support`}
          navigate={closeDrawerAndNavigate}
        >
          {t("menu.Support")}
        </MenuLink>
      </ul>
    </div>
  );

  return (
    <div className={styles.dashboard}>
      <Drawer
        container={document.body}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "var(--color-green-dark)",
            color: "var(--color-secondary-mint)",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "var(--color-green-dark)",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
      <Box
        className={styles.dashboardContent}
        sx={{
          flexGrow: 1,
          marginLeft: { sm: `${drawerWidth}px` },
        }}
      >
        <Breadcrumbs mobileView={true} />
        <Outlet />
      </Box>
    </div>
  );
}

function ShowLogo() {
  return (
    <div className={styles.logoContainer}>
      <Link to="">
        <Logo width={90} height={38} />
      </Link>
    </div>
  );
}

export default function Dashboard() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <NavBar
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        hasDrawer={true}
      />
      <ResponsiveDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  );
}
