import { useCallback, useRef, useState } from "react";

type History<T> = {
  states: Array<T>;
  position: number;
};

export default function useHistory<T>(
  applyState: (state: T) => void,
  initialState: T,
) {
  const history = useRef<History<T>>({
    position: 0,
    states: [initialState],
  });

  const getCan = () => ({
    undo: history.current.position > 0,
    redo: history.current.position < history.current.states.length - 1,
  });
  const [can, setCan] = useState(getCan());

  const edit = useCallback((newState: T) => {
    history.current.states = [
      ...history.current.states.slice(0, history.current.position + 1),
      newState,
    ];
    history.current.position = history.current.states.length - 1;
    setCan(getCan());
    applyState(newState);
  }, []);

  const undo = useCallback(() => {
    if (!getCan().undo) {
      return;
    }
    history.current.position -= 1;
    setCan(getCan());

    applyState(history.current.states[history.current.position]);
  }, [applyState]);

  const redo = useCallback(() => {
    if (!getCan().redo) {
      return;
    }
    history.current.position += 1;
    setCan(getCan());
    applyState(history.current.states[history.current.position]);
  }, [applyState]);

  return { edit, undo, redo, canUndo: can.undo, canRedo: can.redo };
}
