import { MapStyle } from "react-map-gl/maplibre";

const mapStyle: MapStyle = {
  glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
  version: 8,
  sources: {
    empty: {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    },
  },
  sprite: [{ id: "tree-icon", url: `${location.origin}/tree_icon` }],
  layers: [
    { source: "empty", id: "base", type: "fill" },
    { source: "empty", id: "tiling", type: "fill" },
    { source: "empty", id: "field", type: "fill" },
    { source: "empty", id: "result", type: "fill" },
    { source: "empty", id: "tree", type: "fill" },
    { source: "empty", id: "tree-photos", type: "fill" },
  ],
};
export default mapStyle;
