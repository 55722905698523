import { RouteObject } from "react-router";
import LoginCrumb from "./LoginCrumb";
import LoginPage from "./LoginPage";

const route: RouteObject = {
  id: "login",
  path: "login",
  Component: LoginPage,
  handle: {
    Crumb: LoginCrumb,
  },
};

export default route;
