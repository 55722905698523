import React, { JSX, Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Await, useLoaderData, useParams } from "react-router";
import {
  GetFieldParams,
  TreePositions,
  getFieldTrees,
} from "../../../../../API/trees";

import useShowTrees from "./useShowTrees";
import { usePhotosDrivingDay } from "./usePhotosDrivingDay";
import NoTreeSnackbar from "./NoTreePostionSnackbar";
import NoSensorImagesSnackbar from "./NoSensorImagesSnackbar";
import { TreeLayer } from "../../../../../Component/Map/Layers/TreeLayer";
import { useLoading } from "../../../../../Component/LoadingSpinner/LoadingContext";
import {
  TreePhotoPositions,
  TreeScoutData,
  getPhotosByDrivingDay,
  DrivingDay,
} from "../../../../../API/drivingDay";
import { PhotoLayer } from "../../../../../Component/Map/Layers/PhotoLayer";

function SelectFieldFallback() {
  const { setLoading } = useLoading();
  const treeScoutData = useLoaderData() as TreeScoutData;

  useEffect(() => {
    setLoading(true);
    return () => {
      setLoading(false);
    };
  }, [treeScoutData?.drivingDays]);

  return <></>;
}

export default function SelectedField(): JSX.Element {
  const params = useParams();
  const { setLoading } = useLoading();

  const [showTrees] = useShowTrees();
  const [photosDrivingDay] = usePhotosDrivingDay();

  const treeScoutData = useLoaderData() as TreeScoutData;

  const [trees, setTrees] = useState<TreePositions | undefined>();
  const [photo, setPhoto] = useState<Promise<{ url: string }> | undefined>(
    undefined,
  );
  const [photosGeojson, setPhotosGeojson] = useState<
    TreePhotoPositions | undefined
  >(undefined);

  useEffect(() => {
    if (photosDrivingDay) {
      setLoading(true);
      getPhotosByDrivingDay(photosDrivingDay).then(
        (geojson: TreePhotoPositions) => {
          setPhotosGeojson(geojson);
          setLoading(false);
        },
      );
    } else {
      setPhotosGeojson(undefined);
    }
  }, [photosDrivingDay]);

  useEffect(() => {
    if (showTrees) {
      setLoading(true);
      getFieldTrees({ params } as GetFieldParams).then((data) => {
        setTrees(data);
        setLoading(false);
      });
    } else {
      setTrees(undefined);
    }
  }, [showTrees]);

  return (
    <Suspense fallback={<SelectFieldFallback />}>
      <>
        <Outlet />
        <Await resolve={treeScoutData?.drivingDays}>
          {(drivingDays: DrivingDay[]) => {
            if (!drivingDays || drivingDays.length === 0) {
              return null;
            }
            return <NoTreeSnackbar />;
          }}
        </Await>
        {showTrees && trees?.features?.length && <TreeLayer trees={trees} />}
        {photosGeojson?.features.length && (
          <PhotoLayer
            photos={photosGeojson}
            active={photo}
            setActive={setPhoto}
          />
        )}
        {photosGeojson?.features.length === 0 && <NoSensorImagesSnackbar />}
      </>
    </Suspense>
  );
}
