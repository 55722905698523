import { RouteObject } from "react-router";
import FieldPage from "../../../../Component/Field/FieldPage";
import field from "./_field_id";
import FieldPageCrumb from "./FieldPageCrumb";
import newField from "./new";

const routes: RouteObject = {
  path: "field",
  handle: {
    Crumb: FieldPageCrumb,
  },
  Component: FieldPage,
  children: [newField, field],
};

export default routes;
