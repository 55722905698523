import React, { JSX, useMemo } from "react";
import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { Layer, Source } from "react-map-gl/maplibre";
import { FillLayerSpecification } from "maplibre-gl";
import { Outlet } from "react-router";
import {
  ColorSpecification,
  ExpressionSpecification,
  InterpolationSpecification,
} from "@maplibre/maplibre-gl-style-spec";
import MapControl from "../MapControl";
import BlossomClusterCountLegend from "./BlossomClusterCountLegend";
import Popup from "../Popup";
import { CutoffRange } from "../../../routes/customer/_customer_id/taskmap/Taskmap";

const NUMBER_OF_COLORS = 8;

export type BlossomClusterCountFeatureProperties = {
  blossom_cluster_count: number;
  [other: string]: unknown;
};
export type BlossomClusterCountFeatureCollection = FeatureCollection<
  Polygon,
  BlossomClusterCountFeatureProperties
>;

export type BlossomLayerProps = {
  geojson: BlossomClusterCountFeatureCollection;
  cutoff?: CutoffRange;
  onHover?: (feature: BlossomClusterCountFeatureProperties | null) => void;
};

function clusterCount(
  feature:
    | Feature<Geometry, BlossomClusterCountFeatureProperties>
    | null
    | undefined,
): number {
  return Number(feature?.properties.blossom_cluster_count);
}
type InterpolationExpression = [
  "interpolate",
  InterpolationSpecification,
  number | ExpressionSpecification,
  ...(number | number[] | ColorSpecification | ExpressionSpecification)[],
];

export function BlossomClusterCountLayer({
  geojson,
  cutoff,
}: BlossomLayerProps): JSX.Element {
  const { paint, maxCount, minCount } = useMemo(() => {
    const rootStyle = getComputedStyle(document.documentElement);
    const maxCount = Math.max(...geojson.features.map(clusterCount));
    const minCount = Math.min(...geojson.features.map(clusterCount));
    const paint: FillLayerSpecification["paint"] = {
      "fill-color": [...Array(NUMBER_OF_COLORS).keys()].reduce(
        (
          prev: InterpolationExpression,
          current: number,
        ): InterpolationExpression => [
          ...prev,
          (current * maxCount) / NUMBER_OF_COLORS,
          [
            "to-color",
            rootStyle.getPropertyValue("--color-blossom-map-" + current),
          ],
        ],
        [
          "interpolate",
          ["linear"],
          ["number", ["get", "blossom_cluster_count"]],
        ],
      ),
      "fill-opacity": 0.7,
    };
    return { paint, maxCount, minCount };
  }, [geojson]);

  return (
    <>
      <Source id="blossom" type="geojson" data={geojson}>
        <Outlet />
        <Layer
          beforeId="result"
          type="fill"
          id="blossom-fill"
          filter={[
            "all",
            ["!=", ["get", "blossom_cluster_count"], 0],
            [">=", ["get", "blossom_cluster_count"], cutoff ? cutoff.min : 0],
            [
              "<=",
              ["get", "blossom_cluster_count"],
              cutoff ? cutoff.max : 10000,
            ],
          ]}
          paint={paint}
        />
        <Popup layer="blossom-fill" component={TreeInformation} />
      </Source>
      <MapControl position="bottom-left">
        <BlossomClusterCountLegend
          maxCount={Math.round(maxCount)}
          minCount={minCount}
        />
      </MapControl>
    </>
  );
}

function TreeInformation({
  properties,
}: {
  properties: BlossomClusterCountFeatureProperties;
}) {
  return <strong>{Math.round(properties.blossom_cluster_count)}</strong>;
}
