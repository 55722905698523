import { Layer, Source } from "react-map-gl/maplibre";
import React, { JSX } from "react";
import MapControl from "../MapControl";
import styles from "../../Field/FieldOverviewPage.module.css";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export enum PossibleLayers {
  Satellite = "satellite",
  Street = "street",
}

export default function BaseLayer(): JSX.Element {
  const [layer, setLayer] = React.useState<PossibleLayers>(
    PossibleLayers.Satellite,
  );

  return (
    <>
      <MapControl position="top-right">
        <div className={styles.controlPane}>
          <LayerSelect activeLayer={layer} onSelect={setLayer} />
        </div>
      </MapControl>
      <BaseMapLayer activeLayer={layer} />
    </>
  );
}

type BaseMapLayerProps = {
  activeLayer: PossibleLayers;
};

export function BaseMapLayer({ activeLayer }: BaseMapLayerProps): JSX.Element {
  return (
    <>
      <Source
        type="raster"
        tiles={["https://tile.openstreetmap.org/{z}/{x}/{y}.png"]}
        tileSize={256}
        maxzoom={19}
        attribution={
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      >
        <Layer
          layout={{
            visibility:
              activeLayer === PossibleLayers.Street ? "visible" : "none",
          }}
          type="raster"
          beforeId="base"
        />
      </Source>
      <Source
        type="raster"
        tiles={[
          "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        ]}
        tileSize={256}
        maxzoom={19}
        attribution={
          'Powered by <a href="https://www.esri.com">Esri</a> | <span class="esri-dynamic-attribution">DigitalGlobe, GeoEye, i-cubed, USDA, USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community</span>'
        }
      >
        <Layer
          layout={{
            visibility:
              activeLayer === PossibleLayers.Satellite ? "visible" : "none",
          }}
          type="raster"
          beforeId="base"
        />
      </Source>
    </>
  );
}

type LayerSelectProps = {
  onSelect: (layer: PossibleLayers) => void;
  activeLayer: PossibleLayers;
};

export function LayerSelect({ onSelect, activeLayer }: LayerSelectProps) {
  const { t } = useTranslation();

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={activeLayer}
        exclusive
        onChange={(_, value) => {
          if (value) {
            onSelect(value);
          }
        }}
        aria-label={t("label.Select.layer") as string}
      >
        <ToggleButton value={PossibleLayers.Satellite}>
          {t("label.Satellite")}
        </ToggleButton>
        <ToggleButton value={PossibleLayers.Street}>
          {t("label.Street")}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
