import { RouteObject } from "react-router";
import ForgetPasswordCrumb from "./ForgetPasswordCrumb";
import ForgotPassword from "./ForgotPassword";

const routes: RouteObject = {
  id: "forgot-password",
  path: "/forgot-password",
  Component: ForgotPassword,
  handle: {
    Crumb: ForgetPasswordCrumb,
  },
};

export default routes;
