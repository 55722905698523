import React, { JSX, useMemo } from "react";
import { useControl } from "react-map-gl/maplibre";
import { createPortal } from "react-dom";
import { ControlPosition } from "react-map-gl/src/types/common";

type MapControlProps = {
  position: ControlPosition;
  children: React.ReactNode;
};

export default function MapControl({
  position = "bottom-left",
  children,
}: MapControlProps): JSX.Element {
  const control = useMemo(() => document.createElement("div"), []);

  useControl(
    () => ({
      onAdd() {
        return control;
      },
      onRemove() {
        return;
      },
      getDefaultPosition() {
        return "bottom-left";
      },
    }),
    { position },
  );
  return createPortal(children, control);
}
