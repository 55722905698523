import { ActionFunctionArgs } from "react-router";
import { useAuth } from "../../../API/auth";
import { redirect } from "react-router-dom";
import { getUrl } from "../../../API/AureaApi";

export async function actionRegisterAccount({
  request,
}: ActionFunctionArgs): Promise<Response | object> {
  const { token, ...formData } = Object.fromEntries(await request.formData());
  const email = JSON.parse(atob((token as string).split(".")[1])).email;

  const tokenResponse = await fetch(getUrl("/v1/register_account"), {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (tokenResponse.status < 300) {
    useAuth.getState().setTokens(await tokenResponse.json(), email);
    return redirect("/");
  }

  return tokenResponse.json();
}
