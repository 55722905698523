import { bbox } from "@turf/turf";
import React, { JSX, useEffect, useMemo, useState } from "react";
import { LngLatBoundsLike, Map } from "react-map-gl/maplibre";
import { mapTransformRequest } from "../../../../API/AureaApi";
import { mapStyle } from "../../../../Component/Map";
import BaseLayer from "../../../../Component/Map/Layers/BaseLayer";
import { BlossomClusterCountFeatureCollection } from "../../../../Component/Map/Layers/BlossomClusterCount";
import { BlossomFeatureCollection } from "../../../../Component/Map/Layers/BlossomLayer";
import ResultSetLayer from "../../../../Component/Map/Layers/ResultSetLayer";
import TaskmapWidget from "./TaskmapWidget";
import { VigourFeatureCollection } from "../../../../Component/Map/Layers/VigourLayer";
import { useTranslation } from "react-i18next";
import { FeatureCollection, Geometry } from "geojson";

export type TaskMapProps = {
  geojson:
    | BlossomFeatureCollection
    | BlossomClusterCountFeatureCollection
    | VigourFeatureCollection;
};

export type CutoffRange = { min: number; max: number };
export type ApplicationRate = { low: number; high: number };

function getTaskMapProperty(
  geojson: FeatureCollection<Geometry, object>,
): string {
  if ("blossom_area_cm2" in geojson.features[0].properties) {
    return "blossom_area_cm2";
  } else if ("blossom_cluster_count" in geojson.features[0].properties) {
    return "blossom_cluster_count";
  } else if ("vigour_class" in geojson.features[0].properties) {
    return "vigour_class";
  }
  return "";
}

export default function TaskMap({ geojson }: TaskMapProps): JSX.Element {
  const { t } = useTranslation();
  const [cutoff, setCutoff] = useState<CutoffRange>({ min: 0, max: 10000 });
  const [rate, setRate] = useState<ApplicationRate>({ low: 0, high: 100 });

  const productHeaders: { [key: string]: string } = {
    blossom_area_cm2: t("heading.Blossom area"),
    blossom_cluster_count: t("heading.Blossom count"),
    vigour_class: t("heading.Vigour Class"),
  };

  const key = getTaskMapProperty(geojson);

  const sortedData = useMemo(
    () =>
      geojson.features
        .map((feature): number => Number(feature.properties[key]))
        .sort((a, b) => a - b),
    [geojson],
  );

  useEffect(
    () =>
      setCutoff({
        min: Math.floor(sortedData[Math.floor(sortedData.length / 2)]),
        max: Math.ceil(sortedData[sortedData.length - 1]),
      }),
    [geojson],
  );

  return (
    <div>
      <TaskmapWidget
        header={productHeaders[key]}
        sortedData={sortedData}
        cutoff={cutoff}
        setCutoff={setCutoff}
        rate={rate}
        setRate={setRate}
      />
      <Map
        mapStyle={mapStyle}
        transformRequest={mapTransformRequest}
        initialViewState={{
          bounds: bbox(geojson) as LngLatBoundsLike,
        }}
        style={{ width: "100%", height: "70vh" }}
      >
        <BaseLayer />
        <ResultSetLayer geojson={geojson} cutoff={cutoff} />
      </Map>
    </div>
  );
}
