import { useAuth } from "./auth";
import { LoaderFunctionArgs, Params } from "@remix-run/router/utils";
import { getUrl } from "./AureaApi";

export type GetResultSetLayerParams = LoaderFunctionArgs & {
  params: Params<"resultSetId">;
};

export default async function getResultSetLayer({
  params: { resultSetId },
}: GetResultSetLayerParams): Promise<null | object> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    return null;
  }

  const response = await fetch(
    getUrl(`/v1/result_set/${resultSetId}/geojson`),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return JSON.parse(await response.text());
}
