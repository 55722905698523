import { Alert, Button, Snackbar } from "@mui/material";
import React, { JSX, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useParams,
  useRevalidator,
  useRouteLoaderData,
} from "react-router";
import {
  FieldProperties,
  FieldsAndResults,
  saveNewField,
} from "../../../../../API/fields";
import Draw, { DrawRenderControl } from "../../../../../Component/Map/Draw";
import styles from "../../../../../Component/Field/FieldOverviewPage.module.css";
import { MessageLevel } from "../../../../../Component/message-flash/MessageFlash";
import FieldPropertiesDialog from "../components/FieldProperties/FieldPropertiesDialog";

type SubmitData = {
  name: string;
  crop: string;
  variety: string;
  remarks: string;
  plantDistance: number;
  plantingDate: string;
};

function EditButtons({ cancel, save, info }: Parameters<DrawRenderControl>[0]) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.controlPane}>
        <Button
          type="submit"
          size="large"
          variant="text"
          disableElevation
          onClick={cancel}
        >
          {t("button.Edit field properties") as string}
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disableElevation
          onClick={save}
          disabled={!info || !info.valid}
        >
          {t("button.Save changes") as string}
        </Button>
      </div>
    </>
  );
}

export default function NewFieldProperties(): JSX.Element | null {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [result, setResult] = useState<MessageLevel | null>(null);
  const { fields } = useRouteLoaderData("customer") as FieldsAndResults;

  const [editedData, setEditedData] = useState<SubmitData>({
    name: "",
    crop: "",
    variety: "",
    remarks: "",
    plantDistance: 0,
    plantingDate: "",
  });

  const close = () => navigate("..");
  const revalidator = useRevalidator();

  const [open, setOpen] = useState<boolean>(true);

  if (result) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={close}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={result} sx={{ width: "100%" }}>
          {result}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <>
      <Draw
        onCancel={() => setOpen(true)}
        onSave={async (features) => {
          const result = await saveNewField(Number(customerId), {
            name: editedData.name,
            crop: editedData.crop,
            variety: editedData.variety,
            planting_distance_meters: editedData.plantDistance * 0.01,
            plant_date: editedData.plantingDate
              ? new Date(editedData.plantingDate).toISOString()
              : undefined,
            remark: editedData.remarks,
            boundary: features.features[0].geometry,
          });

          let messageLevel = MessageLevel.Error;
          if (result.status == 201) {
            messageLevel = MessageLevel.Success;
            const jsonResult = (await result.json()) as FieldProperties;
            revalidator.revalidate();
            navigate(
              `/customer/${jsonResult.customer_id}/field/${jsonResult.id}`,
            );
          }
          setResult(messageLevel);
        }}
        renderControls={EditButtons}
      />
      <FieldPropertiesDialog
        open={open}
        onClose={close}
        message={null}
        existingFieldNames={fields.features.map(
          (feature) => feature.properties.name,
        )}
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.target as HTMLFormElement);
          const updatedData = Object.fromEntries(
            formData.entries(),
          ) as unknown as SubmitData;

          setEditedData(updatedData);
          setOpen(false);
        }}
        defaultData={editedData}
      />
    </>
  );
}
