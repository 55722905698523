import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { JSX, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldProperties } from "../../../../../API/fields";
import styles from "../orders.module.css";
import useOrder from "../useOrder";

export type NewOrderFormProps = {
  fields: FieldProperties[];
  initialFields: string[];
  onSubmit: () => void;
};

export default function NewOrderForm({
  fields,
  onSubmit,
}: NewOrderFormProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="dashboard-page">
      <h1>{t("order.title.New order")}</h1>
      <div className="vspace">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <OrderFields fields={fields} />
          </Grid>
        </Grid>
      </div>
      <div className="vspace">
        <OrderShowProducts />
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="vspace">
            <OrderDateRange />
          </div>
          <OrderComment />
          <div className="vspace">
            <Button
              onClick={() => onSubmit()}
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.Submit order")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

type OrderFieldsProps = {
  fields: NewOrderFormProps["fields"];
};

function OrderFields({ fields }: OrderFieldsProps): JSX.Element {
  const { t } = useTranslation();
  const { fieldIds, setFieldIds } = useOrder();

  return (
    <FormControl fullWidth>
      <InputLabel id="order-fields-label">{t("label.Fields")}</InputLabel>
      <Select
        labelId="order-fields-label"
        id="order-fields"
        multiple
        value={fieldIds}
        onChange={(event) => {
          setFieldIds(event.target.value as string[]);
        }}
        input={<OutlinedInput label={t("label.Fields")} />}
        renderValue={(selected) =>
          selected
            .map((id) => fields.find((field) => id === `${field.id}`)?.name)
            .join(", ")
        }
        fullWidth
      >
        {fields.map((field) => (
          <MenuItem key={field.id} value={`${field.id}`}>
            <Checkbox checked={fieldIds.some((id) => id === `${field.id}`)} />
            <ListItemText primary={field.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function OrderShowProducts(): JSX.Element {
  const { t } = useTranslation();
  const { product, setProduct } = useOrder();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
        <FormLabel className={styles.labelGroupLabel}>
          {t("order.label.Products")}
        </FormLabel>
        <RadioGroup
          className={styles.labelGroup}
          name="order-product"
          onChange={(_, value) => setProduct(value)}
          value={product || null}
        >
          <label className={styles.label} htmlFor={"product-blossom-map"}>
            <Radio
              value="OR2"
              id="product-blossom-map"
              className={styles.labelInput}
              checked={product === "OR2"}
            />
            <h3>{t("order.product.blossom.title")}</h3>
            <Typography variant="body1" className={styles.labelCaption}>
              {t("order.product.blossom.caption")}
            </Typography>
          </label>
          <label className={styles.label} htmlFor={"product-vigour-map"}>
            <Radio
              value="OR1"
              id="product-vigour-map"
              className={styles.labelInput}
              checked={product === "OR1"}
            />
            <h3>{t("order.product.vigour.title")}</h3>
            <Typography variant="body1" className={styles.labelCaption}>
              {t("order.product.vigour.caption")}
            </Typography>
          </label>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

function OrderDateRange(): JSX.Element {
  const { t } = useTranslation();
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const { dateStart, dateEnd, setDateStart, setDateEnd } = useOrder();

  return (
    <>
      <div className="vspace">
        <InputLabel id="order-date-label">{t("label.Date range")}</InputLabel>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <DatePicker
            label={t("order.label.Period start")}
            value={dateStart}
            open={startOpen}
            slotProps={{
              textField: {
                onClick: () => setStartOpen(true),
              },
            }}
            onClose={() => setStartOpen(false)}
            format={"d LLL y"}
            onChange={(value) => value && setDateStart(value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <DatePicker
            label={t("order.label.Period end")}
            open={endOpen}
            slotProps={{
              textField: {
                onClick: () => setEndOpen(true),
              },
            }}
            onClose={() => setEndOpen(false)}
            value={dateEnd}
            format={"d LLL y"}
            onChange={(value) => value && setDateEnd(value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

function OrderComment(): JSX.Element {
  const { t } = useTranslation();
  const { comments, setComments } = useOrder();

  return (
    <TextField
      fullWidth
      label={t("order.label.Comments")}
      margin="normal"
      multiline
      rows={4}
      value={comments}
      onChange={(event) => setComments(event.target.value)}
      id="order-coomments"
    />
  );
}
