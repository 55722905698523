import React, { JSX, useEffect, useRef } from "react";
import { useMatches } from "react-router-dom";
import { DocumentTitleProps, generateCrumbText } from "./Breadcrumb";

export function DocumentTitle({ suffix }: DocumentTitleProps): JSX.Element {
  const matches = useMatches();
  const ref = useRef<HTMLElement | null>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    const divider = ref.current.innerText ? "-" : "";
    document.title = `${ref.current.innerText} ${divider} ${suffix}`;
  });

  const last = matches
    .map((match) => generateCrumbText(match))
    .findLast((crumb) => crumb);
  return (
    <div key="crumb" style={{ display: "none" }} ref={ref}>
      {last}
    </div>
  );
}
