import { LoaderFunction } from "@remix-run/router/utils";
import React from "react";
import { Navigate, Outlet, RouteObject, useLocation } from "react-router";
import getCustomerOrders from "../../../../API/orders";
import confirm from "./confirm";
import newOrder from "./new";
import OrderPageCrumb from "./OrderPageCrumb";

const routes: RouteObject = {
  path: "order",
  loader: getCustomerOrders as LoaderFunction,
  handle: {
    Crumb: OrderPageCrumb,
  },
  Component: Outlet,
  children: [
    {
      index: true,
      Component(): React.JSX.Element {
        const { search } = useLocation();
        return React.createElement(Navigate, { to: `new${search}` });
      },
    },
    newOrder,
    confirm,
  ],
};

export default routes;
