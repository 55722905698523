import { getUrl } from "./AureaApi";

export async function sendResetPasswordEmail(
  email: string,
  language: string,
): Promise<Response> {
  return await fetch(getUrl("/password_reset"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, language_code: language }),
  });
}
