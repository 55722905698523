import { RouteObject, defer } from "react-router";
import FieldCrumb from "./FieldCrumb";
import edit from "./edit";
import editExtent from "./edit-extent";
import resultSet from "./result-set";
import SelectedField from "./SelectedField";
import {
  GetDrivingDayByFieldParams,
  getDrivingDayByFieldId,
} from "../../../../../API/drivingDay";

const routes: RouteObject = {
  id: "field",
  path: ":fieldId",
  handle: {
    Crumb: FieldCrumb,
  },
  Component: SelectedField,
  children: [edit, editExtent, resultSet],
  loader: (params) => {
    return defer({
      drivingDays: getDrivingDayByFieldId(params as GetDrivingDayByFieldParams),
    });
  },
};

export default routes;
