import { RouteObject } from "react-router";
import { actionUpdateFieldExtent } from "../../../../../../API/fields";
import EditExtent from "./EditExtent";
import i18next from "i18next";
import { Router } from "@remix-run/router";

const routes: RouteObject = {
  path: "edit-extent",
  Component: EditExtent,
  action: actionUpdateFieldExtent,

  handle: {
    confirmLeave(router: Router, to: string) {
      if (router.state.location.pathname.includes(to)) {
        return false;
      }
      return confirm(
        i18next.t(
          "Are you sure you want to cancel editing the extent?",
        ) as string,
      );
    },
  },
};

export default routes;
