import { useAuth } from "./auth";
import { getUrl } from "./AureaApi";

export type Terminal = {
  type: string;
};

export type MachineParameters = {
  type: string;
};

export type TaskmapParameters = {
  cutoff_low: string;
  cutoff_high: string;
  rate_low: number;
  rate_high: number;
  machine_parameters: MachineParameters;
};

export type Properties = {
  terminal: Terminal;
  parameters: TaskmapParameters;
};

export default async function getTaskMap(
  properties: Properties,
  resultSetId: string | undefined,
): Promise<Response | null> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    return null;
  }
  return await fetch(getUrl(`/v1/result_set/${resultSetId}/task_map`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(properties),
  });
}
