import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router";
import { DocumentTitle } from "../Component/Breadcrumbs/DocumentTitle";
import Snackbar from "../Component/message-flash/Snackbar";

export default function RootComponent(): JSX.Element {
  const state = useLocation().state;
  const { t } = useTranslation();

  return (
    <>
      <DocumentTitle suffix={t("site_name")} />
      <Outlet />
      {state && state.snackbar && <Snackbar {...state.snackbar} />}
    </>
  );
}
