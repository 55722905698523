import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import { FieldProperties, Tiling } from "../../API/fields";
import useDronePhoto from "../../routes/customer/_customer_id/field/_field_id/useDronePhoto";
import FieldInformation from "../../routes/customer/_customer_id/field/components/FieldInformation/FieldInformation";
import FieldSelect, {
  FieldResultMap,
} from "../../routes/customer/_customer_id/field/components/FieldSelect/FieldSelect";
import { ResultSetSelect } from "../../routes/customer/_customer_id/field/components/ResultSet/ResultSetSelect";
import TilingSelect from "../../routes/customer/_customer_id/field/components/TilingSelect/TilingSelect";
import BaseLayer from "../Map/Layers/BaseLayer";
import MapControl from "../Map/MapControl";
import FieldMap, { FieldFeature } from "./FieldMap";
import styles from "./FieldOverviewPage.module.css";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useLoading } from "../LoadingSpinner/LoadingContext";

export type FieldOverviewPageProps = {
  geojson: { type: "FeatureCollection"; features: FieldFeature[] };
  fieldResultSetsMap: FieldResultMap;
  selectedFieldId: number | undefined;
  onSelectField: (fieldId: number, customerId?: number | null) => void;
  selectedResultSetId: string | undefined;
  onOrderProduct: (
    sku: string,
    fieldId: number,
    customerId: number | null,
  ) => void;
  onDownloadResult: (
    resultSetId: string,
    fieldId: number,
    customerId: number,
  ) => void;
  onCreateTaskMap: (
    resultSetId: string,
    fieldId: number,
    customerId: number,
  ) => void;
  onSelectResultSet: (
    resultSetId: string | null,
    fieldId: number,
    customerId: number,
  ) => void;
  tilings: Tiling[];
  children?: React.ReactNode;
};

export default function FieldOverviewPage({
  geojson,
  fieldResultSetsMap,
  selectedFieldId = undefined,
  onSelectField,
  selectedResultSetId = undefined,
  onSelectResultSet,
  tilings,
  children,
  onCreateTaskMap,
  onDownloadResult,
  onOrderProduct,
}: FieldOverviewPageProps): JSX.Element {
  const selectedField = geojson.features.find(
    (field) => field.properties.id === selectedFieldId,
  );

  const [photo] = useDronePhoto();
  const { loading } = useLoading();

  return (
    <div className={styles.fieldPage}>
      <div className={styles.fieldSelector}>
        <FieldSelect
          featureList={geojson.features}
          onSelectField={(feature: FieldFeature) => {
            onSelectField(
              feature.properties.id,
              feature.properties.customer_id,
            );
          }}
          selectedFieldId={selectedFieldId}
        />
      </div>
      {loading && <LoadingSpinner />}
      <div className={styles.mapFiller}>
        <FieldMap
          selectedFieldId={selectedFieldId}
          onClick={(feature: FieldFeature) => {
            onSelectField(
              feature.properties.id,
              feature.properties.customer_id,
            );
          }}
          tiling={
            tilings && tilings.find((tiling) => tiling.id.toString() === photo)
          }
          geojson={geojson}
        >
          <BaseLayer />
          {children}

          <MapControl position="top-left">
            {selectedField && (
              <div className={styles.controlPane}>
                <SelectedTabs
                  selectedField={selectedField.properties}
                  selectedResultSetId={selectedResultSetId}
                  onSelectResultSet={onSelectResultSet}
                  fieldResultSetsMap={fieldResultSetsMap}
                  onOrderProduct={onOrderProduct}
                  onDownloadResult={onDownloadResult}
                  onCreateTaskMap={onCreateTaskMap}
                  tilingSelector={
                    <TilingSelect tilings={tilings} bounds={selectedField} />
                  }
                />
              </div>
            )}
          </MapControl>
        </FieldMap>
      </div>
    </div>
  );
}

type SelectedTabsProps = Pick<
  FieldOverviewPageProps,
  | "onSelectResultSet"
  | "selectedResultSetId"
  | "fieldResultSetsMap"
  | "onCreateTaskMap"
  | "onDownloadResult"
  | "onOrderProduct"
> & {
  selectedField: FieldProperties;
  tilingSelector: React.ReactNode;
};

function SelectedTabs({
  selectedField,
  selectedResultSetId,
  onSelectResultSet,
  fieldResultSetsMap,
  onCreateTaskMap,
  onDownloadResult,
  onOrderProduct,
  tilingSelector,
}: SelectedTabsProps) {
  const { t } = useTranslation();
  const [tab, setTab] = React.useState(0);
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
          <Tab label={t("tab.Analysis results")} />
          <Tab label={t("tab.Field information")} />
        </Tabs>
      </Box>
      {tab === 1 && <FieldInformation fieldProperties={selectedField} />}
      {tab === 0 && (
        <ResultSetSelect
          key={selectedField.id}
          resultSetList={fieldResultSetsMap[selectedField.id] || []}
          selectedResultSetId={selectedResultSetId}
          onSelect={(selectedResultSetId) =>
            onSelectResultSet(
              selectedResultSetId,
              selectedField.id,
              selectedField.customer_id,
            )
          }
          onCreateTaskMap={(selectedResultSetId) =>
            onCreateTaskMap(
              selectedResultSetId,
              selectedField.id,
              selectedField.customer_id,
            )
          }
          onDownloadResult={(selectedResultSetId) =>
            onDownloadResult(
              selectedResultSetId,
              selectedField.id,
              selectedField.customer_id,
            )
          }
          onOrderProduct={(product) =>
            onOrderProduct(product, selectedField.id, selectedField.customer_id)
          }
          tilingSelector={tilingSelector}
        />
      )}
    </>
  );
}
