import { useSearchParams } from "react-router-dom";

export default function useDronePhoto(): [
  string | null,
  (photo: string | null) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const dronePhoto = searchParams.get("dronePhoto") || null;
  const setDronePhoto = (newPhoto: string | null): void => {
    if (!newPhoto) {
      searchParams.delete("dronePhoto");
    } else {
      searchParams.set("dronePhoto", newPhoto);
    }
    setSearchParams(searchParams);
  };
  return [dronePhoto, setDronePhoto];
}
