import { MapLayerEventType } from "maplibre-gl";
import { useEffect } from "react";
import { MapLayerMouseEvent, useMap } from "react-map-gl/maplibre";

export default function useLayerEvent<T extends keyof MapLayerEventType>(
  event: T,
  layer: string,
  handler: (event: MapLayerEventType[T]) => void,
): void {
  const { current: map } = useMap();
  useEffect(() => {
    if (map) {
      map.on(event, layer, handler);

      return () => {
        map.off(event, layer, handler);
      };
    }
  }, [event, map, layer]);
}

export function useFeatureAction(
  layer: string,
  handler: (event: MapLayerMouseEvent) => void,
): void {
  const { current: map } = useMap();
  useEffect(() => {
    if (map) {
      const click = (event: MapLayerMouseEvent) => {
        handler(event);
        event.target.getCanvas().style.cursor = "";
      };
      const enter = (event: MapLayerMouseEvent) => {
        event.target.getCanvas().style.cursor = "pointer";
      };
      const leave = (event: MapLayerMouseEvent) => {
        event.target.getCanvas().style.cursor = "";
      };

      map.on("click", layer, click);
      map.on("mouseenter", layer, enter);
      map.on("mouseleave", layer, leave);

      return () => {
        map.off("click", layer, click);
        map.off("mouseenter", layer, enter);
        map.off("mouseleave", layer, leave);
      };
    }
  }, [map, layer]);
}
