import React, { JSX, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import DisplayDate from "../../../../../../Component/DisplayDate/DisplayDate";
import styles from "./ResultSetSelect.module.css";
import Button from "../../../../../../Component/Button/Button";
import { VisibilityOff } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ProductName,
  translateProductName,
} from "../../../../../../Component/Product/ProductName";
import { Await, useRouteLoaderData } from "react-router";
import useShowTrees from "../../_field_id/useShowTrees";
import { usePhotosDrivingDay } from "../../_field_id/usePhotosDrivingDay";
import { DrivingDay, TreeScoutData } from "../../../../../../API/drivingDay";

export enum Products {
  BLOSSOM = "OR2",
  VIGOUR = "OR1",
  TS_BLOSSOM = "TS-BLOSSOM",
  TS_VIGOUR = "TS-VIGOUR",
}

export type ResultSet = {
  product: string;
  date: `${number}-${number}-${number}`;
  result_set_id: string;
  external_visibility: string[];
};

export type ResultSetSelectProps = {
  resultSetList: ResultSet[];
  filter?: string | null;
  selectedResultSetId?: string;
  onSelect: (selectedResultSetId: string | null) => void;
  selectedResultType?: string | null;
  onOrderProduct: (product: Products) => void;
  onDownloadResult: (selectedResultSetId: string) => void;
  onCreateTaskMap: (selectedResultSetId: string) => void;
  tilingSelector: React.ReactNode;
};

function isBlossomProduct(sku: unknown): boolean {
  const blossomProducts: Array<unknown> = [
    Products.BLOSSOM,
    Products.TS_BLOSSOM,
  ];
  return blossomProducts.includes(sku);
}

function isVigourProduct(sku: unknown): boolean {
  const vigourProducts: Array<unknown> = [Products.VIGOUR, Products.TS_VIGOUR];
  return vigourProducts.includes(sku);
}

export function ResultSetSelect({
  resultSetList,
  selectedResultSetId,
  onSelect,
  onOrderProduct,
  onDownloadResult,
  onCreateTaskMap,
  selectedResultType,
  tilingSelector,
}: ResultSetSelectProps) {
  const { t } = useTranslation();
  const [showTrees, setShowTrees] = useShowTrees();
  const [photosDrivingDay, setPhotosDrivingDay] = usePhotosDrivingDay();
  const treeScoutData = useRouteLoaderData("field") as TreeScoutData;

  const [openedResultType, setOpenResultType] = useState<Products | null>(null);
  const foundResult = resultSetList.find(
    (resultSet) => resultSet.result_set_id === selectedResultSetId,
  );
  const resultType = foundResult
    ? foundResult.product
    : openedResultType || selectedResultType;

  const blossomResults = resultSetList.filter((resultSet) =>
    isBlossomProduct(resultSet.product),
  );
  const vigourResults = resultSetList.filter((resultSet) =>
    isVigourProduct(resultSet.product),
  );

  return (
    <List className={styles.resultList}>
      <ListSubheader component="li" className={styles.resultListSubheader}>
        {t("heading.Results")}
      </ListSubheader>
      <ListItem key="no" className={styles.resultListItem}>
        <ListItemButton
          onClick={() => {
            onSelect(null);
            setOpenResultType(null);
          }}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Radio edge="start" disableRipple checked={!resultType} />
          </ListItemIcon>
          <ListItemText primary={t("No results")} />
        </ListItemButton>
      </ListItem>
      <ProductListItem
        onActivate={() => {
          onSelect(blossomResults.at(-1)?.result_set_id || null);
          setOpenResultType(Products.BLOSSOM);
        }}
        product={Products.BLOSSOM}
        active={isBlossomProduct(resultType)}
      >
        <ProductResults
          resultSets={blossomResults}
          selectedResultSetId={selectedResultSetId}
          product={Products.BLOSSOM}
          onOrderProduct={() => onOrderProduct(Products.BLOSSOM)}
          onDownloadResult={() =>
            selectedResultSetId && onDownloadResult(selectedResultSetId)
          }
          onCreateTaskMap={() =>
            selectedResultSetId && onCreateTaskMap(selectedResultSetId)
          }
          onSelect={onSelect}
        />
      </ProductListItem>
      <ProductListItem
        onActivate={() => {
          onSelect(vigourResults.at(-1)?.result_set_id || null);
          setOpenResultType(Products.VIGOUR);
        }}
        product={Products.VIGOUR}
        active={isVigourProduct(resultType)}
      >
        <ProductResults
          resultSets={vigourResults}
          selectedResultSetId={selectedResultSetId}
          product={Products.VIGOUR}
          onOrderProduct={() => onOrderProduct(Products.VIGOUR)}
          onDownloadResult={() =>
            selectedResultSetId && onDownloadResult(selectedResultSetId)
          }
          onCreateTaskMap={() =>
            selectedResultSetId && onCreateTaskMap(selectedResultSetId)
          }
          onSelect={onSelect}
        />
      </ProductListItem>
      <ListSubheader component="li" className={styles.resultListSubheader}>
        {t("heading.Layers")}
      </ListSubheader>
      {tilingSelector}
      <LayerListItem
        label={t("Trees")}
        checked={showTrees}
        onClick={() => setShowTrees(!showTrees)}
      />
      <Suspense>
        <Await resolve={treeScoutData?.drivingDays}>
          {(drivingDays: DrivingDay[]) => {
            const sortedDrivingDays = drivingDays
              ? drivingDays.sort(
                  (a, b) =>
                    Date.parse(b.day_start_time) - Date.parse(a.day_start_time),
                )
              : [];

            return sortedDrivingDays.length ? (
              <LayerListItem
                label={t("Tree photos")}
                checked={photosDrivingDay !== ""}
                onClick={() => {
                  if (photosDrivingDay !== "") {
                    setPhotosDrivingDay("");
                  } else if (sortedDrivingDays.length) {
                    setPhotosDrivingDay(sortedDrivingDays[0].id);
                  }
                }}
              >
                <div className={styles.resultListItemBox}>
                  <div className="vspace">
                    <Select
                      fullWidth
                      value={photosDrivingDay}
                      onChange={(event) => {
                        setPhotosDrivingDay(event.target.value);
                      }}
                    >
                      {sortedDrivingDays.map((drivingDay: DrivingDay) => {
                        return (
                          <MenuItem value={drivingDay.id} key={drivingDay.id}>
                            <DisplayDate date={drivingDay.day_start_time} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </LayerListItem>
            ) : undefined;
          }}
        </Await>
      </Suspense>
    </List>
  );
}

type ProductResultsProps = {
  product: Products;
  resultSets: ResultSetSelectProps["resultSetList"];
  onSelect: ResultSetSelectProps["onSelect"];
  selectedResultSetId: ResultSetSelectProps["selectedResultSetId"];
  onOrderProduct: () => void;
  onDownloadResult: () => void;
  onCreateTaskMap: () => void;
};

function ProductResults({
  product,
  resultSets,
  onSelect,
  selectedResultSetId,
  onOrderProduct,
  onDownloadResult,
  onCreateTaskMap,
}: ProductResultsProps): JSX.Element {
  const { t } = useTranslation();

  if (!resultSets.length) {
    return (
      <div className={styles.resultListItemBox}>
        <p className={styles.resultListItemText}>
          {t("message.There is no {{product}} for this field yet", {
            replace: {
              product: translateProductName(t, product),
            },
          })}
        </p>
        <div className="vspace">
          <Button
            className={styles.resultListButton}
            variant="contained"
            disableElevation
            fullWidth
            size="large"
            onClick={() => onOrderProduct()}
          >
            {t("button.Order {{product}}", {
              replace: {
                product: translateProductName(t, product),
              },
            })}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.resultListItemBox}>
      <div className="vspace">
        <Select
          key={selectedResultSetId}
          fullWidth
          value={selectedResultSetId}
          onChange={(event) => {
            onSelect(event.target.value);
          }}
          data-testid="ResultSetSelect"
        >
          {resultSets.map((rs: ResultSet) => {
            return (
              <MenuItem value={rs.result_set_id} key={rs.result_set_id}>
                <DisplayDate date={rs.date} />
                {!rs.external_visibility.length && (
                  <VisibilityOff
                    className={styles.resultListItemIcon}
                    fontSize="small"
                    titleAccess="Result only visible for Aurea users"
                  />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className="vspace">
        <Button
          className={styles.resultListButton}
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          onClick={() => onCreateTaskMap()}
        >
          {t("button.Create taskmap")}
        </Button>
      </div>
      <div className="vspace">
        <Button
          variant="outlined"
          disableElevation
          fullWidth
          size="large"
          onClick={() => onDownloadResult()}
        >
          {t("button.Download results")}
        </Button>
      </div>
    </div>
  );
}

type ProductSelectProps = {
  product: Products;
  onActivate: () => void;
  active: boolean;
  children: React.ReactNode;
};

function ProductListItem({
  product,
  active,
  onActivate,
  children,
}: ProductSelectProps) {
  return (
    <>
      <ListItem
        className={styles.resultListItem}
        secondaryAction={
          <IconButton edge="end">
            <OpenOrCloseButton open={active} />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => onActivate()}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Radio edge="start" disableRipple checked={active} />
          </ListItemIcon>
          <ListItemText primary={<ProductName sku={product} />} />
        </ListItemButton>
      </ListItem>
      {active && children}
    </>
  );
}

type LayerSelectProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
  children?: React.ReactNode;
};

function LayerListItem({
  label,
  checked,
  onClick,
  children,
}: LayerSelectProps) {
  return (
    <>
      <ListItem
        className={styles.resultListItem}
        onClick={() => onClick()}
        secondaryAction={
          children && (
            <IconButton edge="end">
              <OpenOrCloseButton open={checked} />
            </IconButton>
          )
        }
      >
        <ListItemButton onClick={() => onClick()}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Checkbox edge="start" disableRipple checked={checked} />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
      {checked && children}
    </>
  );
}

function OpenOrCloseButton({ open }: { open: boolean }) {
  if (open) {
    return <KeyboardArrowUpIcon />;
  } else {
    return <KeyboardArrowDownIcon />;
  }
}
