import { create } from "zustand";
import { DateTime } from "luxon";
import { persist } from "zustand/middleware";
import { getUrl } from "./AureaApi";

export class InvalidCredentials extends Error {
  constructor(options: ErrorOptions = {}) {
    super("Invalid credentials for login.", options);
  }
}

export class UnknownLoginError extends Error {
  constructor(
    private responsePayload: object | null,
    options: ErrorOptions = {},
  ) {
    super("An unexpected error occurred during authentication.", options);
  }
}

export class NoToken extends Error {
  constructor(options: ErrorOptions = {}) {
    super("No token has been provided. Please log in first.", options);
  }
}

export class ExpiredRefreshToken extends Error {
  constructor(options: ErrorOptions = {}) {
    super("The refresh token has expired. Please log in again.", options);
  }
}

export type TokenPayload = {
  exp: number;
  iat: number;
  [key: string]: unknown;
};

interface Tokens {
  access_token: string;
  refresh_token: string;
}
export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  username: string | null;
  error: Error | null;
  accessTokenExpiry: DateTime | null;
  accessTokenPayload: TokenPayload | null;
  refreshTokenExpiry: DateTime | null;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  getToken: () => Promise<string | null>;
  logout: () => void;
  setTokens: (token: Tokens, email: string) => void;
}

export const useAuth = create(
  persist<AuthState>(
    (set, get) => {
      async function makeAuthRequest(
        payload: Record<string, string>,
      ): Promise<Response | null> {
        try {
          return await fetch(getUrl("/v1/oauth/token"), {
            method: "POST",
            body: new URLSearchParams(payload),
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          });
        } catch (e) {
          set((state) => {
            state.logout();
            return {
              error: new UnknownLoginError(null, { cause: e }),
            };
          });
          return null;
        }
      }

      async function authenticate(
        payload: Record<string, string>,
      ): Promise<void> {
        const tokenResponse = await makeAuthRequest(payload);
        if (tokenResponse === null) {
          return;
        }

        if (tokenResponse.status === 401) {
          set((state) => {
            state.logout();
            return {
              error: new InvalidCredentials(),
            };
          });
          return;
        }

        const responseJSON = await tokenResponse.json();
        if (tokenResponse.status >= 300) {
          set((state) => {
            state.logout();
            return {
              error: new UnknownLoginError(responseJSON),
            };
          });
          return;
        }

        const accessTokenPayload: TokenPayload = JSON.parse(
          atob(responseJSON.access_token.split(".")[1]),
        );
        const refreshTokenPayload = JSON.parse(
          atob(responseJSON.refresh_token.split(".")[1]),
        );

        set(() => ({
          accessToken: responseJSON.access_token,
          refreshToken: responseJSON.refresh_token,
          accessTokenPayload: accessTokenPayload,
          accessTokenExpiry: DateTime.fromSeconds(accessTokenPayload.exp),
          refreshTokenExpiry: DateTime.fromSeconds(refreshTokenPayload.exp),
          error: null,
        }));
      }

      return {
        accessToken: null,
        refreshToken: null,
        username: null,
        error: null,
        accessTokenExpiry: null,
        accessTokenPayload: null,
        refreshTokenExpiry: null,

        async login({ email, password }) {
          await authenticate({
            username: email,
            password,
            grant_type: "password",
          });

          if (get().error !== null) {
            return;
          }

          set(() => ({
            username: email,
          }));
        },
        async setTokens(tokens: Tokens, email: string) {
          const accessTokenPayload: TokenPayload = JSON.parse(
            atob(tokens.access_token.split(".")[1]),
          );
          const refreshTokenPayload = JSON.parse(
            atob(tokens.refresh_token.split(".")[1]),
          );

          set(() => ({
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token,
            accessTokenPayload: accessTokenPayload,
            accessTokenExpiry: DateTime.fromSeconds(accessTokenPayload.exp),
            refreshTokenExpiry: DateTime.fromSeconds(refreshTokenPayload.exp),
            error: null,
            username: email,
          }));
        },

        async getToken(): Promise<string | null> {
          const {
            accessToken,
            accessTokenExpiry,
            refreshToken,
            refreshTokenExpiry,
          } = get();
          if (accessToken === null) {
            set((state) => {
              state.logout();
              return { error: new NoToken() };
            });
            return null;
          }

          if (
            accessToken &&
            accessTokenExpiry &&
            accessTokenExpiry > DateTime.now()
          ) {
            sendTokenToServiceWorkers(accessToken);
            return accessToken;
          }

          if (
            !refreshToken ||
            (refreshTokenExpiry && refreshTokenExpiry < DateTime.now())
          ) {
            set((state) => {
              state.logout();
              return { error: new ExpiredRefreshToken() };
            });
            return null;
          }

          await authenticate({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          });
          const acc_token = get().accessToken;
          sendTokenToServiceWorkers(acc_token);
          return acc_token;
        },

        logout() {
          set(() => ({
            accessTokenExpiry: null,
            refreshTokenExpiry: null,
            error: null,
            accessToken: null,
            accessTokenPayload: null,
            refreshToken: null,
            username: null,
          }));
        },
      };
    },
    {
      name: "aureaAuth",
    },
  ),
);

function sendTokenToServiceWorkers(accessToken: string | null) {
  navigator.serviceWorker?.controller?.postMessage?.({
    type: "GET_TOKEN",
    token: accessToken,
  });
}
