import { useAuth } from "./auth";
import { getUrl } from "./AureaApi";

interface ApiCustomer {
  portal_id: string | null;
  name: string;
  vat_number?: string | null;
  reseller_id?: number | null;
  id: number;
  created_at: string;
  updated_at: string;
}

export type CustomerList = ApiCustomer[];

export async function getAllCustomers(): Promise<null | Response> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    return null;
  }

  return fetch(getUrl("/v1/customer?limit=2000"), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
