import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import Button from "../../../../../../Component/Button/Button";
import styles from "./FieldPropertiesDialog.module.css";
import { TFunction } from "i18next";
import CropSelect from "./CropSelect";
import { ValidatedTextField } from "../../../../../../Component/Forms/ValidatedFields";

type SubmitData = {
  name: string;
  crop: string;
  variety: string;
  remarks: string;
  plantDistance: number;
  plantingDate: string;
};

interface FieldPropsDialogProps {
  onClose: () => void;
  defaultData: SubmitData;
  message: React.ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
  existingFieldNames: string[];
  open: boolean;
}

function doNothing() {
  // do nothing
}

export function validateName(
  value: string,
  t: TFunction,
  validateContext: string[],
): string | null | undefined | false {
  if (!value) {
    return t("error.The field name is required");
  }
  if (validateContext.includes(value)) {
    return t("error.This field name has already been taken");
  }
  return undefined;
}

export function validatePlantingDistance(
  value: number,
  t: TFunction,
): string | null | undefined | false {
  if (value < 20) {
    return t("error.The planting distance needs to be at least 20 cm");
  }
  return undefined;
}

export default function FieldPropertiesDialog({
  onClose,
  defaultData,
  message = null,
  onSubmit = doNothing,
  existingFieldNames,
  open,
}: FieldPropsDialogProps) {
  const { t } = useTranslation();

  const closeModal = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="modal-title"
    >
      <Form action="." onSubmit={onSubmit} method="POST">
        <DialogTitle id="modal-title">
          {t("title.Field properties")}
        </DialogTitle>
        <div className="vspace">{message}</div>
        <DialogContent>
          <div className="vspace">
            <ValidatedTextField
              name="name"
              label={t("label.Name of field")}
              defaultValue={defaultData.name}
              fullWidth
              required
              validate={validateName}
              validateContext={existingFieldNames}
            />
          </div>
          <div className="vspace">
            <CropSelect defaultValue={defaultData.crop} />
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Variety")}
              name="variety"
              defaultValue={defaultData.variety}
              fullWidth
            />
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Remarks (field)")}
              defaultValue={defaultData.remarks}
              name="remarks"
              fullWidth
            />
          </div>
          <div className="vspace">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  label={t("label.Planting date")}
                  defaultValue={defaultData.plantingDate}
                  name="plantingDate"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ValidatedTextField
                  type="number"
                  label={t("label.Planting distance")}
                  defaultValue={defaultData.plantDistance}
                  name="plantDistance"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">cm</InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                  inputProps={{
                    min: 20,
                  }}
                  validate={validatePlantingDistance}
                  validateContext={undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
              <Button
                onClick={closeModal}
                type="button"
                size="large"
                variant="text"
                fullWidth
                disableElevation
              >
                {t("button.Cancel")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                fullWidth
                disableElevation
              >
                {t("button.Save changes")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
