import { DateTime } from "luxon";
import { create } from "zustand";

type OrderFields = {
  fieldIds: string[];
  product?: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  comments: string;
};

type Setters = {
  [Property in keyof OrderFields as `set${Capitalize<string & Property>}`]-?: (
    value: OrderFields[Property],
  ) => void;
};

export default create<OrderFields & Setters>((set) => ({
  fieldIds: [],
  product: undefined,
  dateStart: DateTime.now(),
  dateEnd: DateTime.now().plus({ days: 7 }),
  comments: "",
  setFieldIds: (fieldIds) => set(() => ({ fieldIds })),
  setProduct: (product) => set(() => ({ product })),
  setDateStart: (dateStart) => set(() => ({ dateStart })),
  setDateEnd: (dateEnd) => set(() => ({ dateEnd })),
  setComments: (comments) => set(() => ({ comments })),
}));
