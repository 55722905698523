import { TextField } from "@mui/material";
import React, { JSX, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InvalidCredentials } from "../../../API/auth";
import Button from "../../../Component/Button/Button";
import MessageFlash, {
  MessageLevel,
} from "../../../Component/message-flash/MessageFlash";

export type OnLogin = (params: {
  email: string;
  password: string;
}) => Promise<void>;

type LoginPageProps = {
  onLogin: OnLogin;
  error: Error | null;
};

function LoginFlash({ error }: { error: Error | null }): JSX.Element {
  const { t } = useTranslation();

  const errorMap = new Map<unknown, null | string>([
    [null, null],
    [undefined, null],
    [InvalidCredentials, t("message.Invalid login credentials")],
  ]);

  const result = errorMap.get(error?.constructor);
  const msg = result !== undefined ? result : t("message.Unknown login error");

  return <MessageFlash message={msg} level={MessageLevel.Error} />;
}

export default function LoginForm({
  onLogin,
  error = null,
}: LoginPageProps): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("title.login")}</h1>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onLogin({ email, password });
        }}
      >
        <fieldset className="boxed">
          <div className="vspace">
            <LoginFlash error={error} />
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Email")}
              required={true}
              variant="outlined"
              fullWidth={true}
              value={email}
              type="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Password")}
              fullWidth={true}
              variant="outlined"
              required={true}
              value={password}
              type="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
            />
          </div>
          <div className="vspace">
            <Link to="/forgot-password">{t("link.Forgot password")}</Link>
          </div>
          <div className="vspace">
            <Button
              data-testid="button.login"
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.login")}
            </Button>
          </div>

          <div className="vspace">
            <Link to="/verify-email">
              <Button
                size="large"
                variant="contained"
                fullWidth
                disableElevation
                color="secondary"
              >
                {t("button.register")}
              </Button>
            </Link>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
