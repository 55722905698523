import React, { JSX } from "react";
import {
  BlossomClusterCountFeatureCollection,
  BlossomClusterCountLayer,
} from "./BlossomClusterCount";
import BlossomLayer, { BlossomFeatureCollection } from "./BlossomLayer";
import VigourLayer, { VigourFeatureCollection } from "./VigourLayer";
import { CutoffRange } from "../../../routes/customer/_customer_id/taskmap/Taskmap";

export type ResultSetGeoJSON =
  | BlossomFeatureCollection
  | BlossomClusterCountFeatureCollection
  | VigourFeatureCollection;

export type ResultSetLayerProps = {
  geojson: ResultSetGeoJSON;
  cutoff?: CutoffRange;
};

function isDroneBlossom(
  result: ResultSetGeoJSON,
): result is BlossomFeatureCollection {
  return "blossom_area_cm2" in result.features[0].properties;
}

function isTmsBlossom(
  result: ResultSetGeoJSON,
): result is BlossomClusterCountFeatureCollection {
  return "blossom_cluster_count" in result.features[0].properties;
}

function isVigour(result: ResultSetGeoJSON): result is VigourFeatureCollection {
  return "vigour_class" in result.features[0].properties;
}

export default function ResultSetLayer({
  geojson,
  cutoff = { min: 0, max: 10000 },
}: ResultSetLayerProps): JSX.Element | null {
  if (isDroneBlossom(geojson)) {
    return <BlossomLayer geojson={geojson} cutoff={cutoff} />;
  }
  if (isTmsBlossom(geojson)) {
    return <BlossomClusterCountLayer geojson={geojson} cutoff={cutoff} />;
  }
  if (isVigour(geojson)) {
    return <VigourLayer geojson={geojson} cutoff={cutoff} />;
  }

  return null;
}
