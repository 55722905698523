import type { RequestTransformFunction } from "maplibre-gl";
import { useAuth } from "./auth";
import { redirect } from "react-router-dom";

export function getOrigin(): string {
  return import.meta.env.VITE_AUREA_API_ORIGIN || location.origin;
}

export function getUrl(endpoint: string): string {
  return `${getOrigin()}/${endpoint.replace(/^\//, "")}`;
}

export async function get<T>(url: string): Promise<T> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    throw redirect("/login");
  }
  const response = await fetch(getUrl(url), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.status === 401) {
    throw redirect("/login");
  }
  if (response.headers.get("Content-Type") === "application/geo+json") {
    return JSON.parse(await response.text());
  }
  return response.json();
}

export async function getImage(url: string) {
  const token = await useAuth.getState().getToken();
  const response = await fetch(getUrl(url), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return URL.createObjectURL(await response.blob());
}

export const mapTransformRequest: RequestTransformFunction = (url) => {
  const { origin, pathname } = new URL(url);
  const token = useAuth.getState().accessToken;

  if (origin.endsWith(".aureaimaging.com") && pathname.startsWith("/v1")) {
    return {
      url,
      headers: { Authorization: `Bearer ${token}` },
    };
  }
};
