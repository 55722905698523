export type Terminal = {
  id: string;
};

export type TerminalList = Array<Terminal>;

export default function getTerminals(): TerminalList {
  return [
    {
      id: "mueller",
    },
    {
      id: "fendtone",
    },
  ];
}
