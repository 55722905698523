import React, { JSX } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FieldInformation.module.css";
import { FieldProperties } from "../../../../../../API/fields";
import DisplayDate from "../../../../../../Component/DisplayDate/DisplayDate";
import Grid from "@mui/material/Grid";

export type FieldInformationProps = {
  fieldProperties: FieldProperties;
};

type FieldPropertyProps = {
  children: React.ReactNode;
  label: string;
};

function FieldProperty({ label, children }: FieldPropertyProps): JSX.Element {
  if (children) {
    return (
      <Grid spacing={3} container className={styles.propertyRow}>
        <Grid item xs={6}>
          <span className={styles.propertyKey}>{label}</span>
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
      </Grid>
    );
  }
  return <></>;
}

export default function FieldInformation({
  fieldProperties,
}: FieldInformationProps): JSX.Element {
  const { t, i18n } = useTranslation();

  const fmt = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className={styles.fieldInformation}>
      <h3>{fieldProperties.name}</h3>
      <FieldProperty label={t("label.Crop")}>
        {fieldProperties.crop}
      </FieldProperty>
      <FieldProperty label={t("label.Variety")}>
        {fieldProperties.variety}
      </FieldProperty>
      <FieldProperty label={t("label.Planted")}>
        <DisplayDate date={fieldProperties.plant_date} />
      </FieldProperty>
      <FieldProperty label={t("label.Planting distance")}>
        {t("text.{{distance}}.m", {
          distance: fmt.format(fieldProperties.planting_distance_meters || 0),
        })}
      </FieldProperty>
      <FieldProperty label={t("label.Area")}>
        {t("text.{{area}}.ha", {
          area: fmt.format(fieldProperties.area_ha),
        })}
      </FieldProperty>
      {fieldProperties.remark && (
        <div className={styles.propertyRow}>
          <span className={styles.propertyKey}>{t("label.Remarks")}</span>
          <span className={styles.propertyLine}>{fieldProperties.remark}</span>
        </div>
      )}
    </div>
  );
}
