import {
  Router as RemixRouter,
  RouterNavigateOptions,
} from "@remix-run/router";
import { To } from "react-router";

export default function enhanceRouter(router: RemixRouter): RemixRouter {
  const _navigate = router.navigate;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  router.navigate = async (
    to: To,
    { confirm = true, ...opts }: RouterNavigateOptions & { confirm?: boolean },
  ) => {
    if (!confirm || !router.state.matches || "formMethod" in opts) {
      return _navigate(to, opts);
    }
    const match = router.state.matches.findLast((match) => {
      return (
        match.route.handle &&
        typeof match.route.handle === "object" &&
        "confirmLeave" in match.route.handle
      );
    }) as
      | undefined
      | {
          route: {
            handle: {
              confirmLeave: (
                router: RemixRouter,
                to: To | null,
                opts?: RouterNavigateOptions,
              ) => boolean;
            };
          };
        };
    const confirmLeave = match ? match.route.handle.confirmLeave : () => true;
    if (confirmLeave(router, to, opts)) {
      return _navigate(to, opts);
    }
  };
  return router;
}
