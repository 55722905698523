import React, { JSX } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import styles from "./button.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";

type ButtonProps = MuiButtonProps & {
  className?: string;
  to?: string; // Undocumented feature in MUI
};

export default function Button({
  className = "",
  ...props
}: ButtonProps): JSX.Element {
  return (
    <MuiButton
      LinkComponent={Link}
      className={classNames([styles.button, className])}
      {...props}
    />
  );
}
