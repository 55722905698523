import React, { Suspense, useEffect } from "react";
import { Await, useLoaderData } from "react-router";
import ResultSetLayer, {
  ResultSetGeoJSON,
} from "../../../../../../Component/Map/Layers/ResultSetLayer";
import { useLoading } from "../../../../../../Component/LoadingSpinner/LoadingContext";

function ResultSetPageFallback() {
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    return () => {
      setLoading(false);
    };
  }, []);

  return <></>;
}

export default function ResultSetPage() {
  const data = useLoaderData() as { geojson: ResultSetGeoJSON };
  return (
    <Suspense fallback={<ResultSetPageFallback />}>
      <Await resolve={data.geojson}>
        {(geojson) => <ResultSetLayer geojson={geojson} />}
      </Await>
    </Suspense>
  );
}
